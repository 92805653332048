import { Link } from 'gatsby';
import React from 'react';
import { joinClasses } from './join-classes';

interface Props {
  url: string;
  text: string;
  classNames?: string;
  hoverWhite?: boolean;
}

export default (props: Props) => {
  const { url, text, classNames, hoverWhite } = props;

  const classes = ['text-orange bold', classNames];

  if (hoverWhite) classes.push('hover-white');

  return (
    <div className="mt-2">
      <Link to={url} className={joinClasses(...classes)}>
        {text}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 link-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  );
};
