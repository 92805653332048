import React from 'react';
import DocumentTemplates from '../components/DocumentTemplates';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import QualityManualBuild from '../pages-partials/quality-manual/QualityManualBuild';
import QualityManualExample from '../pages-partials/quality-manual/QualityManualExample';
import QualityManualHero from '../pages-partials/quality-manual/QualityManualHero';
import QualityManualNeeded from '../pages-partials/quality-manual/QualityManualNeeded';

const QualityManualPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('QualityManualSeoTitle')}
      description={t('QualityManualSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/quality-manual"
      lang={locale}
    />
    <QualityManualHero />
    <QualityManualBuild />
    <QualityManualRequestWithBg hideLink />
    <DocumentTemplates />
    <QualityManualNeeded />
    <QualityManualExample />
  </Layout>
);

export default withI18next({ ns: 'common' })(QualityManualPage);
