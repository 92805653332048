import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../components/images/BackgroundImage';
import QualityManualRequest from '../components/QualityManualRequest';
import urls from '../components/urls';
import QMCloudsDokumenttiMalliImg from './images/QMCloudsDokumenttiMalliImg';
import LinkTo from './LinkTo';

type Props = {
  hideLink?: boolean;
};

export default ({ hideLink }: Props) => {
  const { t } = useTranslation();

  let link: JSX.Element | string = (
    <LinkTo
      url={urls.qualityManualUrl}
      text={t('ReadMoreQualityManual')}
      hoverWhite
    />
  );

  if (hideLink) link = '';

  return (
    <>
      <BackgroundImage>
        <Container className="container-space">
          <Row>
            <Col lg={6}>
              <QMCloudsDokumenttiMalliImg className="img-w-300" />
            </Col>
            <Col lg={6} className="mt-4">
              <h2 className="text-white">{t('DownloadDocumentModelTitle')}</h2>
              <div className="line white-line" />
              <p className="text-white">{t('DownloadDocumentModelP')}</p>
              <QualityManualRequest isBackgroundBlue />
              {link}
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </>
  );
};
