import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsLaatudokumentitImage from '../../components/images/QMCloudsLaatudokumentitImage';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6}>
            <h2 className="text-white">{t('QualityManualBuildTitle2')}</h2>
            <p>{t('QualityManualBuildPara5')}</p>
          </Col>
          <Col lg={6}>
            <h2 className="text-white">{t('QualityManualBuildTitle3')}</h2>
            <ul>
              <li className="mb-2">{t('QualityManualBuildListItem1')}</li>
              <li className="mb-2">{t('QualityManualBuildListItem2')}</li>
              <li className="mb-2">{t('QualityManualBuildListItem3')}</li>
              <li className="mb-2">{t('QualityManualBuildListItem4')}</li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <QMCloudsLaatudokumentitImage className="img-rounded" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
