import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initValues = {
  email: '',
};

type QualityManualRequestFormBody = typeof initValues;

interface Props {
  isBackgroundBlue?: boolean;
}

export default (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isQualityManualRequestSend, setIsQualityManualRequestSend] =
    useState(false);
  const { isBackgroundBlue = false } = props;

  const [isInvalid, setIsInvalid] = useState(false);

  let textClassName = '';

  const qualityManualRequest = useCallback(
    (values: QualityManualRequestFormBody) => {
      if (values.email === '') {
        setIsInvalid(true);
      } else {
        let url = 'https://formspree.io/mqkbynyp';
        if (process.env.NODE_ENV !== 'production') {
          url = 'https://formspree.io/xpzrrlkb';
        }

        const body = {
          message: `Lähetä laatukäsikirjan malli (QMClouds): ${values.email}`,
          _subject: `Laatukäsikirja: ${values.email}`,
        };
        window.gtag('event', 'submit', {
          event_category: 'Quality manual request',
        });

        Axios.post(url, body)
          .then(() => {
            setIsQualityManualRequestSend(true);
          })
          .catch(() => {
            setIsInvalid(true);
          });
      }
    },
    [setIsInvalid]
  );

  if (isBackgroundBlue === true) {
    textClassName = 'text-white';
  }

  return (
    <Formik initialValues={initValues} onSubmit={qualityManualRequest}>
      {isQualityManualRequestSend ? (
        <Row>
          <Col>
            <h5 className="text-success font-weight-bold">
              {t('QualityManualRequestSend')}
            </h5>
          </Col>
        </Row>
      ) : (
        <Form className="contact-request-form">
          <Row>
            <Col>
              <div>
                <p className={`${textClassName} font-weight-bold mb-2`}>
                  {t('QualityManualRequestLeaveYourInformation')}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t(
                    'QualityManualRequestLeaceYourInformationPlaceholder'
                  )}
                  name="email"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-full"
                >
                  {t('QualityManualRequestButton')}
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {isInvalid ? (
                <p className="mt-2">{t('QualityManualRequestErrorMessage')}</p>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
