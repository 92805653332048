import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkTo from './LinkTo';
import urls from './urls';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="container-space" id="documents">
      <Row>
        <Col>
          <h3 className="text-blue text-center">
            {t('DocumentTemplatesTitle')}
          </h3>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6} className="mb-4">
          <h4>{t('DocumentTemplatesSecondTitle')}</h4>
          <div className="line blue-line" />
          <ul>
            <li>{t('DocumentTemplatesListItem1')}</li>
            <li>{t('DocumentTemplatesListItem2')}</li>
            <li>{t('DocumentTemplatesListItem3')}</li>
            <li>{t('DocumentTemplatesListItem4')}</li>
            <li>{t('DocumentTemplatesListItem5')}</li>
            <li>{t('DocumentTemplatesListItem6')}</li>
            <li>{t('DocumentTemplatesListItem7')}</li>
            <li>{t('DocumentTemplatesListItem8')}</li>
            <li>{t('DocumentTemplatesListItem9')}</li>
            <li>{t('DocumentTemplatesListItem10')}</li>
            <li>{t('DocumentTemplatesListItem11')}</li>
          </ul>
          <LinkTo url={urls.qualitySystemUrl} text={t('ReadMoreQuality')} />
          <LinkTo
            url={urls.environmentalSystem}
            text={t('ReadMoreEnvironmental')}
          />
          <LinkTo
            url={urls.occupationalSafetySystem}
            text={t('ReadMoreSafety')}
          />
        </Col>
        <Col lg={6}>
          <h4>{t('DocumentTemplatesThirdTitle')}</h4>
          <div className="line blue-line" />
          <ul>
            <li>{t('DocumentTemplatesListItem12')}</li>
            <li>{t('DocumentTemplatesListItem13')}</li>
            <li>{t('DocumentTemplatesListItem14')}</li>
            <li>{t('DocumentTemplatesListItem15')}</li>
            <li>{t('DocumentTemplatesListItem16')}</li>
            <li>{t('DocumentTemplatesListItem17')}</li>
            <li>{t('DocumentTemplatesListItem18')}</li>
            <li>{t('DocumentTemplatesListItem19')}</li>
            <li>{t('DocumentTemplatesListItem20')}</li>
            <li>{t('DocumentTemplatesListItem21')}</li>
            <li>{t('DocumentTemplatesListItem22')}</li>
            <li>{t('DocumentTemplatesListItem23')}</li>
            <li>{t('DocumentTemplatesListItem24')}</li>
            <li>{t('DocumentTemplatesListItem25')}</li>
            <li>{t('DocumentTemplatesListItem26')}</li>
            <li>{t('DocumentTemplatesListItem27')}</li>
            <li>{t('DocumentTemplatesListItem28')}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
