import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsDokumenttiMalliImg from '../../components/images/QMCloudsDokumenttiMalliImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col lg={8}>
            <h1>{t('QualityManualHeroTitle')}</h1>
            <p>{t('QualityManualHeroParagraph')}</p>
          </Col>
          <Col lg={4}>
            <QMCloudsDokumenttiMalliImg className="img-w-300" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
