import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsLaatudokasikirjaImage from '../../components/images/QMCloudsLaatukasikirja';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col>
          <h2 className="text-blue">{t('QualityManualBuildTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('QualityManualBuildPara1')}</p>
          <p>{t('QualityManualBuildPara2')}</p>
          <p>{t('QualityManualBuildPara3')}</p>
          <p>{t('QualityManualBuildPara4')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <QMCloudsLaatudokasikirjaImage className="img-shadow img-rounded" />
        </Col>
      </Row>
    </Container>
  );
};
