import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import QMCloudsLaptopLaatudokumenttiImg from '../../components/images/QMCloudsLaptopLaatudokumenttiImg';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6} className="mb-4">
          <h2 className="text-blue">{t('QualityManualIncludingTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('QualityManualIncludingPara1')} </p>
          <p>{t('QualityManualIncludingPara2')}</p>
          <p>{t('QualityManualIncludingPara3')}</p>
          <QMCloudsLaptopLaatudokumenttiImg />
          <div className="mt-3" />
          <ContactRequest />
          <div className="mt-4" />
          <LinkTo
            url={urls.occupationalSafetySystem}
            text={t('ReadMoreSafety')}
          />
          <LinkTo
            url={urls.environmentalSystem}
            text={t('ReadMoreEnvironmental')}
          />
          <LinkTo url={urls.qualitySystemUrl} text={t('ReadMoreQuality')} />
        </Col>
        <Col lg={6}>
          <h3 className="text-blue">{t('QualityManualExampleTitle')}</h3>
          <ol className="blue-list">
            <li className="mb-1">{t('QualityManualExampleListItem1')}</li>
            <li className="mb-1">{t('QualityManualExampleListItem2')}</li>
            <li className="mb-1">{t('QualityManualExampleListItem3')}</li>
            <li className="mb-1">
              {t('QualityManualExampleListItem4')}
              <ol type="a">
                <li>{t('QualityManualExampleListItem5')}</li>
                <li> {t('QualityManualExampleListItem6')}</li>
                <li> {t('QualityManualExampleListItem7')}</li>
              </ol>
            </li>
            <li className="mb-1">
              {t('QualityManualExampleListItem8')}
              <ol type="a">
                <li> {t('QualityManualExampleListItem9')}</li>
                <li> {t('QualityManualExampleListItem10')}</li>
                <li> {t('QualityManualExampleListItem11')}</li>
              </ol>
            </li>
            <li className="mb-1">
              {t('QualityManualExampleListItem12')}
              <ol type="a">
                <li> {t('QualityManualExampleListItem13')}</li>
                <li>{t('QualityManualExampleListItem14')}</li>
              </ol>
            </li>
            <li className="mb-1">{t('QualityManualExampleListItem15')}</li>
            <li className="mb-1">
              {t('QualityManualExampleListItem16')}
              <ol type="a">
                <li>{t('QualityManualExampleListItem17')}</li>
                <li>{t('QualityManualExampleListItem18')}</li>
                <li>{t('QualityManualExampleListItem19')}</li>
                <li>{t('QualityManualExampleListItem20')}</li>
                <li>{t('QualityManualExampleListItem21')}</li>
                <li>{t('QualityManualExampleListItem22')}</li>
                <li>{t('QualityManualExampleListItem23')}</li>
                <li>{t('QualityManualExampleListItem24')}</li>
                <li>{t('QualityManualExampleListItem25')}</li>
                <li>{t('QualityManualExampleListItem26')}</li>
                <li>{t('QualityManualExampleListItem27')}</li>
              </ol>
            </li>
            <li className="mb-1">
              {t('QualityManualExampleListItem28')}
              <ol type="a">
                <li>{t('QualityManualExampleListItem29')}</li>
                <li>{t('QualityManualExampleListItem30')}</li>
              </ol>
            </li>
            <li className="mb-1">
              {t('QualityManualExampleListItem31')}
              <ol type="a">
                <li>{t('QualityManualExampleListItem32')}</li>
                <li>{t('QualityManualExampleListItem33')}</li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};
